import { useEffect, useRef, useState } from 'react'
import clsx from 'clsx'
import { Helmet } from 'react-helmet'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { Form } from 'entities/Form'
import { Footer, Header } from 'widgets'
import { LinkButton } from 'shared/ui'
import { ROUTES } from 'shared/consts'
import { scrollToForm } from 'shared/helpers'
import HeaderSticky from 'widgets/Header/HeaderSticky'

import bg1 from 'shared/assets/img/Main-page/equipment.webp'
import bg2 from 'shared/assets/img/Main-page/cutting-and-deboning-poultry.webp'
import bg3 from 'shared/assets/img/Main-page/automation-of-production.webp'
import bg4 from 'shared/assets/img/Main-page/engineering.webp'

import chooseBg1 from 'shared/assets/img/Choose-bg/1-gray.jpg'
import chooseBg2 from 'shared/assets/img/Choose-bg/2-gray.jpg'
import chooseBg3 from 'shared/assets/img/Choose-bg/3-gray.jpg'
import chooseBg4 from 'shared/assets/img/Choose-bg/4-gray.jpg'

import chooseBg1Active from 'shared/assets/img/Choose-bg/1.jpg'
import chooseBg2Active from 'shared/assets/img/Choose-bg/2.jpg'
import chooseBg3Active from 'shared/assets/img/Choose-bg/3.jpg'
import chooseBg4Active from 'shared/assets/img/Choose-bg/4.jpg'

import sms from 'shared/assets/icons/sms-tracking.svg'

import s from './Styles.module.scss'
import { AboutUs, ContactInformation, OurClients } from './ui'

function Main() {
  const data = [
    {
      bg: s.bg1,
      title: `Производим оборудование`,
      subTitle: `Мясопереработка - наша тема`,
      text: `Собственное производство и многолетний опыт наших специалистов помогут Вам добиться самых смелых целей и решить даже нестандартные задачи. `,
      to: () => {
        const newSearchParams = new URLSearchParams(searchParams.toString())

        navigate(ROUTES.EQUIPMENTS)

        newSearchParams.set('category', 42)
        newSearchParams.set('page', 0)
        newSearchParams.delete('subcategory')
        setSearchParams(newSearchParams)
      }
    },
    {
      bg: s.bg2,
      title: `Разделка и обвалка птицы`,
      subTitle: `Технологичность и качество`,
      text: `Производим оборудование различных типов и мощностей для разделки и обвалки птицы.`,
      to: () => {
        const newSearchParams = new URLSearchParams(searchParams.toString())

        navigate(ROUTES.EQUIPMENTS)

        newSearchParams.set('category', 40)
        newSearchParams.set('page', 0)
        newSearchParams.delete('subcategory')
        setSearchParams(newSearchParams)
      }
    },
    {
      bg: s.bg3,
      title: `Автоматизация производства`,
      subTitle: `Эффективное распределение ресурсов`,
      text: `Наше оборудование поможет Вам перейти на новый уровень автоматизации.`,
      to: () => {
        const newSearchParams = new URLSearchParams(searchParams.toString())

        navigate(ROUTES.EQUIPMENTS)

        newSearchParams.set('category', 42)
        newSearchParams.set('page', 0)
        newSearchParams.delete('subcategory')
        setSearchParams(newSearchParams)
      }
    },
    {
      bg: s.bg4,
      title: `Инжиниринг`,
      subTitle: `Индивидуальные решения`,
      text: `Разработаем и реализуем решения, которые идеально подойдут для Вашего производства. `,
      to: () => navigate(ROUTES.ENGINEERING)
    }
  ]
  const [activeData, setActiveData] = useState(data[0])
  const [intervalId, setIntervalId] = useState(null)
  const [searchParams, setSearchParams] = useSearchParams()
  const [, setLoadedImages] = useState([])
  const navigate = useNavigate()
  const containerRef = useRef(null)

  useEffect(() => {
    const preloadImages = async () => {
      const images = [
        bg1,
        bg2,
        bg3,
        bg4,
        chooseBg1Active,
        chooseBg2Active,
        chooseBg3Active,
        chooseBg4Active,
        chooseBg1,
        chooseBg2,
        chooseBg3,
        chooseBg4
      ]

      try {
        const preloadedImages = await Promise.all(
          images.map((image) => {
            return new Promise((resolve, reject) => {
              const img = new Image()
              img.src = image
              img.onload = resolve
              img.onerror = reject
            })
          })
        )

        setLoadedImages(preloadedImages)
      } catch (error) {
        console.error('Ошибка при загрузке изображений:', error)
      }
    }

    const startImageSwitch = () => {
      preloadImages().then(() => {
        const id = setInterval(() => {
          setActiveData((prevData) => {
            if (prevData === data[0]) {
              return data[1]
            } else if (prevData === data[1]) {
              return data[2]
            } else if (prevData === data[2]) {
              return data[3]
            } else if (prevData === data[3]) {
              return data[0]
            }
          })
        }, 6000)

        setIntervalId(id)
      })
    }

    startImageSwitch()

    window.scrollTo(0, 0)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Helmet>
        <title>MIROTEC</title>
      </Helmet>

      <HeaderSticky containerRef={containerRef} />

      <div className={s.preloadedImages}>
        <div className={clsx(s.preloadedImage, s.bg1)}></div>
        <div className={clsx(s.preloadedImage, s.bg2)}></div>
        <div className={clsx(s.preloadedImage, s.bg3)}></div>
        <div className={clsx(s.preloadedImage, s.bg4)}></div>
      </div>

      <div className={s.container}>
        <div onClick={activeData?.to} className={clsx(s.wrapper, activeData?.bg)}>
          <div className={s.indent}>
            <Header containerRef={containerRef} />
          </div>

          <h1 className={s.mainTitle}>{activeData.title}</h1>

          <div className={s.wrap}>
            <div className={s.subTitleWrapperHeight}>
              <div className={s.subTitleWrapper}>
                <h6 className={s.subTitle}>{activeData.subTitle}</h6>
                <p className={s.text}>{activeData.text}</p>
              </div>
            </div>
            <div className={s.chooseBgWrapper}>
              <button
                onClick={(e) => {
                  e.stopPropagation()
                  setActiveData(data[0])
                  clearInterval(intervalId)
                }}
                className={clsx(s.chooseBgButton)}
              >
                <img
                  src={activeData.title === data[0].title ? chooseBg1Active : chooseBg1}
                  alt="Bacground cover"
                  className={clsx(activeData.title === data[0].title && s.active)}
                />
              </button>
              <button
                onClick={(e) => {
                  e.stopPropagation()
                  setActiveData(data[1])
                  clearInterval(intervalId)
                }}
                className={clsx(s.chooseBgButton)}
              >
                <img
                  src={activeData.title === data[1].title ? chooseBg2Active : chooseBg2}
                  alt="Bacground cover"
                  className={clsx(activeData.title === data[1].title && s.active, s.violet)}
                />
              </button>
              <button
                onClick={(e) => {
                  e.stopPropagation()
                  setActiveData(data[2])
                  clearInterval(intervalId)
                }}
                className={clsx(s.chooseBgButton)}
              >
                <img
                  src={activeData.title === data[2].title ? chooseBg3Active : chooseBg3}
                  alt="Bacground cover"
                  className={clsx(activeData.title === data[2].title && s.active)}
                />
              </button>
              <button
                onClick={(e) => {
                  e.stopPropagation()
                  setActiveData(data[3])
                  clearInterval(intervalId)
                }}
                className={clsx(s.chooseBgButton)}
              >
                <img
                  src={activeData.title === data[3].title ? chooseBg4Active : chooseBg4}
                  alt="Bacground cover"
                  className={clsx(activeData.title === data[3].title && s.active)}
                />
              </button>
            </div>
            <div className={s.mobileLink}>
              <LinkButton text="Оставить запрос" icon={sms} alt="sms" onClick={scrollToForm} />
            </div>
          </div>
        </div>

        <div className={s.chooseBgWrapperMobile}>
          <button
            onClick={(e) => {
              e.stopPropagation()
              setActiveData(data[0])
              clearInterval(intervalId)
            }}
            className={s.chooseBgButtonMobile}
          >
            <img
              src={activeData.title === data[0].title ? chooseBg1Active : chooseBg1}
              alt="Bacground cover"
              className={clsx(activeData.title === data[0].title && s.active)}
            />
          </button>
          <button
            onClick={(e) => {
              e.stopPropagation()
              setActiveData(data[1])
              clearInterval(intervalId)
            }}
            className={s.chooseBgButtonMobile}
          >
            <img
              src={activeData.title === data[1].title ? chooseBg2Active : chooseBg2}
              alt="Bacground cover"
              className={clsx(activeData.title === data[1].title && s.active)}
            />
          </button>
          <button
            onClick={(e) => {
              e.stopPropagation()
              setActiveData(data[2])
              clearInterval(intervalId)
            }}
            className={s.chooseBgButtonMobile}
          >
            <img
              src={activeData.title === data[2].title ? chooseBg3Active : chooseBg3}
              alt="Bacground cover"
              className={clsx(activeData.title === data[2].title && s.active)}
            />
          </button>
          <button
            onClick={(e) => {
              e.stopPropagation()
              setActiveData(data[3])
              clearInterval(intervalId)
            }}
            className={s.chooseBgButtonMobile}
          >
            <img
              src={activeData.title === data[3].title ? chooseBg4Active : chooseBg4}
              alt="Bacground cover"
              className={clsx(activeData.title === data[3].title && s.active)}
            />
          </button>
        </div>
      </div>

      <div className={s.containerThree}>
        <OurClients />
      </div>

      <div className={s.containerTwo}>
        <AboutUs />
      </div>

      <ContactInformation />

      <div className={s.footerWrapper}>
        <Form />
        <Footer />
      </div>
    </>
  )
}

export default Main
