import { makeAutoObservable } from 'mobx'
import LoadingModel from 'models/Loading'

import { API } from 'services'

class EquipmentsModel {
  product = {}

  loading = LoadingModel

  constructor() {
    makeAutoObservable(this)

    this.loading = new LoadingModel()
  }

  async fetch(id) {
    try {
      this.loading.begin()

      const data = await API.products.getById(id)

      this.product = data
    } catch (err) {
      console.log(err)
    } finally {
      this.loading.reset()
    }
  }
}

const model = new EquipmentsModel()

export default model
