import s from './Styles.module.scss'

function Project({ number, name, active }) {
  return (
    <div className={active ? s.wrapperActive : s.wrapper}>
      <div className={active ? s.numberActive : s.number}>
        <p>{number}</p>
      </div>
      <p>{name}</p>
    </div>
  )
}

export default Project
