export const ROUTES = {
  MAIN: '/',

  ENGINEERING: '/engineering',

  EQUIPMENTS: '/equipments',

  EQUIPMENT: '/equipment/:id',

  PORTFOLIOS: '/portfolios',

  PORTFOLIO: '/portfolio/:id',

  ABOUT_US: '/about-us',

  CONTACTS: '/contacts'
}
