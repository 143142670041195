import { makeAutoObservable } from 'mobx'

import { API } from 'services'

class FormModel {
  constructor() {
    makeAutoObservable(this)
  }

  async sendRequest(request) {
    await API.request.sendRequest(request)
  }
}

const model = new FormModel()

export default model
