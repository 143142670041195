import { observer } from 'mobx-react-lite'
import { Link, generatePath } from 'react-router-dom'

import { ROUTES } from 'shared/consts'

import s from './Styles.module.scss'

function Project({ id, number, date, name, description, imgSrc }) {
  return (
    <div className={s.wrapper}>
      <p className={s.number}>{number}</p>
      <div className={s.wrapperInfo}>
        <Link to={generatePath(ROUTES.PORTFOLIO, { id: String(id) })}>
          <img src={imgSrc} alt="Machine" />
        </Link>
        <div className={s.textWrapper}>
          <div>
            <p className={s.date}>{date}</p>
            <Link to={generatePath(ROUTES.PORTFOLIO, { id: String(id) })}>{name}</Link>
            <p className={s.description}>{description}</p>
          </div>
          <Link to={generatePath(ROUTES.PORTFOLIO, { id: String(id) })}>Изучить проект</Link>
        </div>
      </div>
    </div>
  )
}

export default observer(Project)
