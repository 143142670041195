import { makeAutoObservable } from 'mobx'

import { API } from 'services'
import LoadingModel from 'models/Loading'

class PortfoliosModel {
  projects = []

  loading = LoadingModel

  constructor() {
    makeAutoObservable(this)
    this.loading = new LoadingModel()
  }

  async fetch() {
    try {
      this.loading.begin()

      const data = await API.projects.get()

      this.projects = data.list
    } catch (err) {
      console.log(err)
    } finally {
      this.loading.reset()
    }
  }
}

const model = new PortfoliosModel()

export default model
