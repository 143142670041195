import clsx from 'clsx'
import { Link } from 'react-router-dom'

import s from './Styles.module.scss'

function LinkButton({ text, icon, alt, to, theme, onClick, size }) {
  return (
    <Link
      onClick={(e) => {
        e.stopPropagation()
        onClick && onClick()
      }}
      to={to}
      className={clsx(
        theme === 'green' ? s.buttonGreen : s.buttonLight,
        theme === 'violet' && s.buttonViolet,
        size === 'small' && s.small
      )}
    >
      {text}
      {icon && <img src={icon} alt={alt} />}
    </Link>
  )
}

export default LinkButton
