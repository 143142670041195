import s from './Styles.module.scss'

function ContactInformation() {
  return (
    <div className={s.container}>
      <div className={s.subTitleWrapper}>
        <p className={s.subTitle}>контактная информация</p>
        <div className={s.subTitleLine}></div>
      </div>

      <h1 className={s.title}>
        Наше расположение
        <br /> на карте
      </h1>

      <div className={s.mapWrapper}>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d2353.7442440161185!2d27.39196340608214!3d53.84741265869178!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNTPCsDUwJzUwLjYiTiAyN8KwMjMnMzIuMyJF!5e0!3m2!1sru!2sby!4v1696922566009!5m2!1sru!2sby"
          allowFullScreen=""
          referrerPolicy="no-referrer-when-downgrade"
          title="Map"
          className={s.map}
        ></iframe>
        <div className={s.contactsWrapper}>
          <div className={s.contacts}>
            <h6 className={s.contactsTitle}>Адрес:</h6>
            <p className={s.textDecorationNone}>Щомыслицкий с-с, 71, Минский р-н, Беларусь</p>
          </div>
          <div className={s.contacts}>
            <h6 className={s.contactsTitle}>Телефон:</h6>
            <a href="tel:+375173000081">+375 17 300-00-81</a>
            <br />
            <a href="tel:+74997033340">+7 499 703-33-40</a>
          </div>
          <div className={s.contacts}>
            <h6 className={s.contactsTitle}>Email:</h6>
            <a href="mailto:sales@mirotec.by">sales@mirotec.by</a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactInformation
