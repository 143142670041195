import { observer } from 'mobx-react-lite'
import { Link, generatePath, useNavigate, useSearchParams } from 'react-router-dom'

import { Spinner } from 'shared/ui'
import { ROUTES } from 'shared/consts'
import { WidgetsModel } from 'widgets/model'
import arrowRight from 'shared/assets/icons/arrows/arrow-right-violet.svg'

import s from './Styles.module.scss'

function EquipmentsMenu() {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()

  return (
    <div
      onClick={(e) => {
        e.stopPropagation()
      }}
      className={s.wrapper}
    >
      {WidgetsModel.loading.has ? (
        <Spinner />
      ) : (
        <>
          {WidgetsModel.categoriesWithProducts?.map((category) => (
            <div key={category.id} className={s.categoryWrapper}>
              <h6
                onClick={() => {
                  const newSearchParams = new URLSearchParams(searchParams.toString())

                  navigate(ROUTES.EQUIPMENTS)

                  newSearchParams.set('category', category.id)
                  newSearchParams.set('page', 0)
                  newSearchParams.delete('subcategory')
                  setSearchParams(newSearchParams)
                }}
              >
                {category.name}
              </h6>

              <div className={s.productsWrapper}>
                {category.productList.map((product) => (
                  <Link
                    onClick={() => {
                      navigate(generatePath(ROUTES.EQUIPMENT, { id: String(product.id) }))
                      WidgetsModel.fetch()
                    }}
                    key={product.id}
                    className={s.product}
                  >
                    <img src={arrowRight} alt="Arrow right" />
                    <p>{product.name}</p>
                  </Link>
                ))}
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  )
}

export default observer(EquipmentsMenu)
