import { useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import clsx from 'clsx'
import { Link, generatePath } from 'react-router-dom'

import { LinkButton } from 'shared/ui'
import { ROUTES } from 'shared/consts'
import arrowRight from 'shared/assets/icons/arrows/arrow-right.svg'

import s from './Styles.module.scss'
import { Box } from '@mui/material'

function Product({ id, name, description, imgUrl }) {
  const descriptionRef = useRef(null)

  useEffect(() => {
    if (description) {
      descriptionRef.current.innerHTML = description
    }
  }, [description])

  return (
    <Link to={generatePath(ROUTES.EQUIPMENT, { id: String(id) })} className={s.productWrapper}>
      <Box
        className={s.productImg}
        sx={{
          backgroundImage: `url(${imgUrl})`,
          '@media (max-width: 1100px)': {
            '&:hover': {
              backgroundImage: `url(${imgUrl})!important`
            }
          }
        }}
      >
        <div className={clsx(s.wrapper, s.displayNone)}>
          <p className={clsx(s.title)}>{name}</p>
          <p className={clsx(s.description)} ref={descriptionRef}></p>
        </div>
        <div className={clsx(s.displayNone, s.widthFitContent)}>
          <LinkButton
            to={generatePath(ROUTES.EQUIPMENT, { id: String(id) })}
            text="Посмотреть товар"
            icon={arrowRight}
            alt="Arrow right"
            size="small"
          />
        </div>
      </Box>
      <Link to={generatePath(ROUTES.EQUIPMENT, { id: String(id) })} className={s.link}>
        {name}
      </Link>
    </Link>
  )
}

export default observer(Product)
