import s from './Styles.module.scss'

function BenefitsOfWorkingWithUs() {
  return (
    <div className={s.wrapper}>
      <div className={s.subTiltleWrapper}>
        <p className={s.subTitle}>преимущества работы с нами</p>
        <div className={s.subTitleLine}></div>
      </div>

      <h1 className={s.title}>в чем мы сильны</h1>

      <div className={s.wrap}>
        <div className={s.advantagesWrapper}>
          <div className={s.advantages}>
            <p className={s.number}>01</p>
            <div className={s.advantagesTextWrapper}>
              <h6>Качество и надежность:</h6>
              <p>
                Все наши изделия производятся в соответствии с самыми строгими стандартами качества,
                чтобы обеспечить надежную и долгосрочную работу.
              </p>
            </div>
          </div>

          <div className={s.mobileDivider}></div>
          <div className={s.lineVertical}></div>

          <div className={s.advantages}>
            <p className={s.number}>02</p>
            <div className={s.advantagesTextWrapper}>
              <h6>Инновации:</h6>
              <p>
                Мы постоянно работаем над разработкой новых технологий и усовершенствованием
                существующих решений, чтобы наши клиенты всегда были на шаг впереди конкурентов.
              </p>
            </div>
          </div>

          <div className={s.mobileDivider}></div>
          <div className={s.lineVertical}></div>

          <div className={s.advantages}>
            <p className={s.number}>03</p>
            <div className={s.advantagesTextWrapper}>
              <h6>Готовность экспериментировать:</h6>
              <p>
                Мы не боимся браться за сложные и нестандартные задачи, именно они позволяют достичь
                наибольшего прогресса и передовых результатов в отрасли.
              </p>
            </div>
          </div>
        </div>

        <div className={s.mobileDivider}></div>

        <div className={s.linesWrapper}>
          <div className={s.lineHorizontal}></div>
          <div className={s.lineHorizontal}></div>
          <div className={s.lineHorizontal}></div>
        </div>

        <div className={s.advantagesWrapper}>
          <div className={s.advantages}>
            <p className={s.number}>04</p>
            <div className={s.advantagesTextWrapper}>
              <h6>Индивидуальный подход:</h6>
              <p>
                Мы готовы разработать решение, которое идеально подойдет именно для Вашего
                производства, учитывая его особенности и потребности.
              </p>
            </div>
          </div>

          <div className={s.mobileDivider}></div>
          <div className={s.lineVertical}></div>

          <div className={s.advantages}>
            <p className={s.number}>05</p>
            <div className={s.advantagesTextWrapper}>
              <h6>Широкий спектр оборудования:</h6>
              <p>
                У нас Вы найдете большой выбор оборудования для мясной промышленности – от разделки
                и обвалки птицы, до подготовки к упаковке готового продукта.
              </p>
            </div>
          </div>

          <div className={s.mobileDivider}></div>
          <div className={s.lineVertical}></div>

          <div className={s.advantages}>
            <p className={s.number}>06</p>
            <div className={s.advantagesTextWrapper}>
              <h6>Профессиональная поддержка:</h6>
              <p>
                Команда специалистов всегда готова помочь Вам выбрать оптимальное оборудование,
                обучить сотрудников и обеспечить качественное обслуживание.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BenefitsOfWorkingWithUs
