import { useEffect, useRef } from 'react'
import { Helmet } from 'react-helmet'
import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router-dom'

import { Footer, Header } from 'widgets'
import { Form } from 'entities/Form'
import HeaderSticky from 'widgets/Header/HeaderSticky'

import s from './Styles.module.scss'
import { ProjectEquipment, ProjectAchievements, ProjectInfo } from './ui'
import { PortfolioModel } from './model'

function Portfolio() {
  const { id } = useParams()
  const containerRef = useRef(null)

  useEffect(() => {
    PortfolioModel.fetch(Number(id))
    window.scrollTo(0, 0)
  }, [id])

  return (
    <>
      <Helmet>
        <title>{PortfolioModel.portfolio.name ?? 'Портфолио'}</title>
      </Helmet>

      <HeaderSticky containerRef={containerRef} />

      <div className={s.headerWrapper}>
        <Header containerRef={containerRef} />
      </div>

      <div className={s.container}>
        <ProjectInfo portfolio={PortfolioModel.portfolio} />
        <ProjectAchievements portfolio={PortfolioModel.portfolio} />
      </div>
      {PortfolioModel.portfolio.productList?.length > 0 && (
        <ProjectEquipment portfolio={PortfolioModel.portfolio} />
      )}
      <div className={s.container}>
        <div>
          <Form />
        </div>
        <Footer />
      </div>
    </>
  )
}

export default observer(Portfolio)
