import s from './Styles.module.scss'
import { Project } from './ui'

function OurProjects({ projects }) {
  return (
    <div className={s.wrapper}>
      <div className={s.subTiltleWrapper}>
        <p className={s.subTitle}>портфолио</p>
        <div className={s.subTitleLine}></div>
      </div>

      <h1 className={s.title}>Наши проекты</h1>
      <div className={s.projectsWrapper}>
        {projects?.map((project, id) => (
          <div key={project.id}>
            <Project
              id={project.id}
              date={project.dateOfCreation}
              number={id + 1}
              name={project.name}
              description={project.shortDescription}
              imgSrc={project.mainPictureUrl.url}
            />

            <div className={s.divider}></div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default OurProjects
