import { useEffect, useRef } from 'react'
import { Helmet } from 'react-helmet'

import { Footer, Header } from 'widgets'
import { Form } from 'entities/Form'
import HeaderSticky from 'widgets/Header/HeaderSticky'

import s from './Styles.module.scss'
import { AboutCompany, BenefitsOfWorkingWithUs, OurTeam } from './ui'

function AboutUs() {
  const containerRef = useRef(null)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Helmet>
        <title>О нас</title>
      </Helmet>

      <HeaderSticky containerRef={containerRef} />
      <div className={s.headerWrapper}>
        <Header containerRef={containerRef} />
      </div>
      <div className={s.container}>
        <AboutCompany />
        <OurTeam />
        <div className={s.mobileWrapper}>
          <BenefitsOfWorkingWithUs />
          <Form />
        </div>
        <Footer />
      </div>
    </>
  )
}

export default AboutUs
