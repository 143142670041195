import { observer } from 'mobx-react-lite'
import { Stack } from '@mui/material'

import { Modal } from 'shared/ui'

import s from './Styles.module.scss'

function PlayerModal({ open, handleClose, videoUrl }) {
  function getIframeUrl(url) {
    if (url?.indexOf('v=') === -1) {
      return `https://www.youtube.com/embed/${url?.substring(
        url?.indexOf('https://youtu.be/') + 17,
        url?.indexOf('?si')
      )}`
    }
    if (url?.indexOf('&t') !== -1) {
      return `https://www.youtube.com/embed/${url?.substring(
        url?.indexOf('v=') + 2,
        url?.indexOf('&t')
      )}`
    } else {
      url = url + '&t'
      return `https://www.youtube.com/embed/${url?.substring(
        url?.indexOf('v=') + 2,
        url?.indexOf('&t')
      )}`
    }
  }

  return (
    <Modal open={open} handleClose={handleClose}>
      <Stack className={s.wrapper}>
        <iframe
          src={getIframeUrl(videoUrl)}
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </Stack>
    </Modal>
  )
}

export default observer(PlayerModal)
