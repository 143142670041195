import { useEffect, useRef } from 'react'
import { Helmet } from 'react-helmet'
import { observer } from 'mobx-react-lite'
import { useNavigate, useSearchParams } from 'react-router-dom'
import clsx from 'clsx'

import { Footer, Header } from 'widgets'
import { Form } from 'entities/Form'
import { getSearchParamsObj, scrollToForm } from 'shared/helpers'
import { BreadCrumbs, LinkButton, Spinner } from 'shared/ui'
import { ROUTES } from 'shared/consts'
import sms from 'shared/assets/icons/sms-tracking.svg'
import HeaderSticky from 'widgets/Header/HeaderSticky'

import { Products } from './ui'
import s from './Styles.module.scss'
import { EquipmentsModel } from './model'

function Equipments() {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const containerRef = useRef(null)

  useEffect(() => {
    EquipmentsModel.initialFetch(searchParams.get('category'), getSearchParamsObj(searchParams))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams.get('category')])

  useEffect(() => {
    EquipmentsModel.debounceNewPageFetch(getSearchParamsObj(searchParams))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams.get('page')])

  useEffect(() => {
    EquipmentsModel.debounceSubcategoryFetch(getSearchParamsObj(searchParams))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams.get('subcategory')])

  useEffect(() => {
    return () => {
      EquipmentsModel.clearModel()
    }
  }, [])

  useEffect(() => {
    if (!searchParams.get('category')) {
      navigate(ROUTES.MAIN)
    }
  }, [navigate, searchParams])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Helmet>
        <title>Оборудование</title>
      </Helmet>

      <HeaderSticky containerRef={containerRef} />
      {EquipmentsModel.initialLoading.has ? (
        <Spinner />
      ) : (
        <div className={s.container}>
          <div className={s.headerWrapper}>
            <Header containerRef={containerRef} />

            <div
              className={s.bgWrapper}
              style={{ backgroundImage: `url(${EquipmentsModel.categories.picture?.url})` }}
              loading="lazy"
            >
              <BreadCrumbs name="Оборудование" />

              <div className={s.textWrapper}>
                <h2 className={s.title}>{EquipmentsModel.categories.name}</h2>
                <div className={s.line}></div>
                <p
                  className={s.text}
                  dangerouslySetInnerHTML={{ __html: EquipmentsModel.categories.description }}
                ></p>
              </div>
            </div>

            <div className={s.mobileLink}>
              <LinkButton
                text="Оставить запрос"
                icon={sms}
                alt="sms"
                onClick={scrollToForm}
                theme="green"
              />
            </div>
          </div>

          <div className={clsx(s.wrap, s.indentation)}>
            {EquipmentsModel.loading.has ? <Spinner /> : <Products />}
            <Form />
          </div>
          <div className={s.indentation}>
            <Footer />
          </div>
        </div>
      )}
    </>
  )
}

export default observer(Equipments)
