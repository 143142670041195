import { BreadCrumbs } from 'shared/ui'
import machine from 'shared/assets/img/About-company/team.jpg'

import s from './Styles.module.scss'

function AboutCompany() {
  return (
    <div className={s.wrapper}>
      <BreadCrumbs name="О нас" theme="dark" />

      <div className={s.title}>О компании</div>

      <div className={s.whoAreWeWrapper}>
        <div className={s.mobileDivider}></div>

        <div className={s.whoAreWeTextWrapper}>
          <h3>Лучшая команда в СНГ</h3>
          <p>
            Mirotec – белорусская компания, объединившая лучших специалистов своей отрасли. Каждый
            член команды обладает опытом более 10-ти лет в вопросах оборудования. Это становится и
            Вашим преимуществом, гарантируя, что каждое решение и консультация, предоставленные
            нами, основаны на устоявшихся знаниях и высокой квалификации.
          </p>
        </div>
        <img src={machine} alt="Machine" />
      </div>
    </div>
  )
}

export default AboutCompany
