import { useEffect, useRef, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import clsx from 'clsx'

import { ROUTES } from 'shared/consts'
import { BreadCrumbs, LinkButton } from 'shared/ui'
import play from 'shared/assets/icons/play.svg'

import s from './Styles.module.scss'
import { PlayerModal } from './ui'

function EquipmentInfo({ product }) {
  const navigate = useNavigate()
  const [mainImg, setMainImg] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()

  const descriptionRef = useRef(null)
  const categoryDescriptionRef = useRef(null)
  const equipmentRef = useRef(null)
  const advantagesRef = useRef(null)

  useEffect(() => {
    descriptionRef.current.innerHTML = product.description
    categoryDescriptionRef.current.innerHTML = product.categoryDescription
    equipmentRef.current.innerHTML = product.equipment
    advantagesRef.current.innerHTML = product.advantages
    if (!mainImg && product.picturesUrls) {
      setMainImg(product.picturesUrls[0].url)
    }
  }, [product, mainImg])

  return (
    <>
      <div className={s.wrapper}>
        <div className={s.head}>
          <BreadCrumbs
            name={product.categoryName}
            secondName={product.name}
            theme="dark"
            onClick={() => {
              const newSearchParams = new URLSearchParams(searchParams.toString())

              navigate(ROUTES.EQUIPMENTS)

              newSearchParams.set('category', product.categoryId)
              newSearchParams.set('page', 0)
              setSearchParams(newSearchParams)
            }}
            isShowMainPageActive={false}
          />

          <div className={s.titleWrapper}>
            <h3 className={s.title}>{product.name}</h3>
            <p className={s.subTitle} ref={categoryDescriptionRef}></p>
          </div>
        </div>

        <div className={s.infoWrapper}>
          <div className={s.imgBlockWrapper}>
            <div>
              <img src={mainImg} alt="Main img" className={s.mainImg} />
              <div className={s.chooseBgWrapper}>
                {product.picturesUrls?.map((img) => (
                  <button key={img.id} onClick={() => setMainImg(img.url)}>
                    <img
                      src={img.url}
                      alt="choose"
                      className={clsx(img.url === mainImg && s.active)}
                    />
                  </button>
                ))}
              </div>
            </div>
            {product.videoUrl && (
              <LinkButton
                onClick={() => setIsModalOpen(true)}
                text="Смотреть видео"
                icon={play}
                alt="Play"
                theme="green"
              />
            )}
          </div>
          <div className={s.textBlockWrapper}>
            <h6 className={s.infoTitle}>01 описание</h6>
            <p className={s.description} ref={descriptionRef}></p>
            <div className={s.divider}></div>

            <h6 className={s.infoTitle}>02 Преимущества</h6>
            <p className={s.description} ref={advantagesRef}></p>
            <div className={s.divider}></div>

            <h6 className={s.infoTitle}>03 Комплектация</h6>
            <p className={s.description} ref={equipmentRef}></p>
          </div>
        </div>
      </div>

      <PlayerModal
        open={isModalOpen}
        videoUrl={product.videoUrl}
        handleClose={() => {
          setIsModalOpen(false)
        }}
      />
    </>
  )
}

export default observer(EquipmentInfo)
