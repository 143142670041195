import { LinkButton } from 'shared/ui'
import { ROUTES } from 'shared/consts'
import arrowRight from 'shared/assets/icons/arrows/arrow-right.svg'

import s from './Styles.module.scss'

function OurClients() {
  return (
    <div className={s.wrapper}>
      <div className={s.wrapperText}>
        <div className={s.subTiltleWrapper}>
          <p className={s.subTitle}>Наши клиенты</p>
          <div className={s.subTitleLine}></div>
        </div>

        <h1 className={s.title}>НАМ ДОВЕРЯЮТ</h1>

        <p className={s.text}>
          Нам доверяют совершенствование своих производственных процессов: Брестский мясокомбинат,
          ПУП "Брестские традиции", Агрокомбинат "Колос" - ТМ "Галерея вкуса", Томская птицефабрика,
          Птицефабрика "Рассвет", ГК "Черкизово", Гродненский мясокомбинат...
        </p>

        <div className={s.buttonWrapper}>
          <LinkButton
            to={ROUTES.PORTFOLIOS}
            theme="green"
            text="Просмотреть портфолио"
            icon={arrowRight}
            alt="Arrow right"
          />
        </div>
      </div>
      <div className={s.img} />
    </div>
  )
}

export default OurClients
