import { useRef } from 'react'
import clsx from 'clsx'
import { observer } from 'mobx-react-lite'
import { Link, generatePath, useNavigate } from 'react-router-dom'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import { ROUTES } from 'shared/consts'
import arrowLeft from 'shared/assets/icons/arrows/arrow-left.svg'
import arrowRight from 'shared/assets/icons/arrows/arrow-right-violet-second.svg'

import s from './Styles.module.scss'

function ProjectEquipment({ portfolio }) {
  const slider = useRef(null)
  const navigate = useNavigate()

  function slideToShow() {
    if (portfolio.productList?.length === 1) {
      return 1
    } else if (portfolio.productList?.length === 2) {
      return 2
    } else {
      return 3
    }
  }

  return (
    <div className={s.wrapper}>
      <h1 className={s.title}>Оборудование проекта</h1>

      <div className={s.sliderWrapper}>
        <Slider
          ref={slider}
          speed={500}
          autoplay
          slidesToShow={slideToShow()}
          slidesToScroll={slideToShow()}
          arrows={false}
          className={portfolio.productList?.length < 4 && s.slider}
        >
          {portfolio.productList?.map((product) => (
            <div
              key={product.id}
              className={clsx(
                slideToShow() === 3 ? s.cursorGrab : s.cursorDefault,
                s.productWrapper
              )}
            >
              <img
                onClick={() => navigate(generatePath(ROUTES.EQUIPMENT, { id: String(product.id) }))}
                src={product.mainPictureUrl.url}
                alt="Продукт"
              />
              <Link to={generatePath(ROUTES.EQUIPMENT, { id: String(product.id) })}>
                {product.name}
              </Link>
            </div>
          ))}
        </Slider>

        <div className={s.slideButtons}>
          <button onClick={() => slider?.current?.slickPrev()}>
            <img src={arrowLeft} alt="Arrow" />
          </button>
          <button onClick={() => slider?.current?.slickNext()}>
            <img src={arrowRight} alt="Arrow" />
          </button>
        </div>
      </div>

      <div className={s.sliderMobileWrapper}>
        <Slider dots speed={500} slidesToShow={1} slidesToScroll={1} arrows={false}>
          {portfolio.productList?.map((product) => (
            <div
              key={product.id}
              className={clsx(
                slideToShow() === 3 ? s.cursorGrab : s.cursorDefault,
                s.productMobileWrapper
              )}
            >
              <img
                onClick={() => navigate(generatePath(ROUTES.EQUIPMENT, { id: String(product.id) }))}
                src={product.mainPictureUrl.url}
                alt="Продукт"
              />
              <Link to={generatePath(ROUTES.EQUIPMENT, { id: String(product.id) })}>
                {product.name}
              </Link>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  )
}

export default observer(ProjectEquipment)
