import { LinkButton } from 'shared/ui'
import arrowRight from 'shared/assets/icons/arrows/arrow-right.svg'
import { ROUTES } from 'shared/consts'

import s from './Styles.module.scss'

function AboutUs() {
  return (
    <div className={s.wrapper}>
      <div className={s.subTiltleWrapper}>
        <p className={s.subTitle}>О компании</p>
        <div className={s.subTitleLine}></div>
      </div>

      <div className={s.aboutWrapper}>
        <h1 className={s.title}>
          Вместе создаем
          <br />
          будущее
          <br />
          мясопереработки
        </h1>

        <div className={s.wrap}>
          <p className={s.text}>
            Компания Mirotec – Ваш надежный производитель оборудования для переработки мяса и птицы.
            Мы предлагаем как готовые решения, уже проверенные и успешно используемые нашими
            клиентами, так и индивидуальные инженерные разработки, созданные эксклюзивно под Ваши
            потребности.
          </p>

          <div className={s.buttonWrapper}>
            <LinkButton
              to={ROUTES.ABOUT_US}
              theme="green"
              text="Подробнее о компании"
              icon={arrowRight}
              alt="Arrow"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default AboutUs
