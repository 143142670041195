import { observer } from 'mobx-react-lite'

import s from './Styles.module.scss'

function ProjectAchievements({ portfolio }) {
  return (
    <div className={s.wrapper}>
      <h3 className={s.title}>Достижения проекта</h3>

      <div className={s.achievementsWrapper}>
        <div className={s.wrap}>
          <div className={s.achievement}>
            <div className={s.textWrapper}>
              <h6>{portfolio.achievementList && portfolio.achievementList[0].name}</h6>
              <p>{portfolio.achievementList && portfolio.achievementList[0].description}</p>
            </div>
            <h6 className={s.number}>01</h6>
          </div>

          <div className={s.achievement}>
            <div className={s.textWrapper}>
              <h6>{portfolio.achievementList && portfolio.achievementList[1].name}</h6>
              <p>{portfolio.achievementList && portfolio.achievementList[1].description}</p>
            </div>
            <h6 className={s.number}>02</h6>
          </div>
        </div>

        <div className={s.wrap}>
          <div className={s.achievement}>
            <div className={s.textWrapper}>
              <h6>{portfolio.achievementList && portfolio.achievementList[2].name}</h6>
              <p>{portfolio.achievementList && portfolio.achievementList[2].description}</p>
            </div>
            <h6 className={s.number}>03</h6>
          </div>

          <div className={s.achievement}>
            <div className={s.textWrapper}>
              <h6>{portfolio.achievementList && portfolio.achievementList[3].name}</h6>
              <p>{portfolio.achievementList && portfolio.achievementList[3].description}</p>
            </div>
            <h6 className={s.number}>04</h6>
          </div>
        </div>
      </div>
    </div>
  )
}

export default observer(ProjectAchievements)
