import { useEffect, useRef } from 'react'
import { Helmet } from 'react-helmet'

import { Footer, Header } from 'widgets'
import { Form } from 'entities/Form'
import { scrollToForm } from 'shared/helpers'
import { BreadCrumbs, LinkButton } from 'shared/ui'
import sms from 'shared/assets/icons/sms-tracking.svg'
import HeaderSticky from 'widgets/Header/HeaderSticky'

import { OurProjects } from './ui'
import s from './Styles.module.scss'

function Engineering() {
  const containerRef = useRef(null)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Helmet>
        <title>Инжиниринг</title>
      </Helmet>

      <HeaderSticky containerRef={containerRef} />
      <div className={s.container}>
        <div className={s.headerWrapper}>
          <Header containerRef={containerRef} />

          <div className={s.bgWrapper}>
            <BreadCrumbs name="Инжиниринг" />

            <div className={s.textWrapper}>
              <h2 className={s.title}>Индивидуальные решения</h2>
              <div className={s.line}></div>
              <p className={s.text}>
                Мы готовы разработать эксклюзивное решение, которое идеально подойдет именно для
                Вашего производства, учитывая его особенности и потребности.
              </p>
            </div>
          </div>

          <div className={s.mobileLink}>
            <LinkButton
              text="Оставить запрос"
              icon={sms}
              alt="sms"
              onClick={scrollToForm}
              theme="green"
            />
          </div>
        </div>

        <OurProjects />

        <div className={s.wrap}>
          <Form />
          <Footer />
        </div>
      </div>
    </>
  )
}

export default Engineering
