import clsx from 'clsx'
import { useFormikContext } from 'formik'
import { observer } from 'mobx-react-lite'

import s from './Styles.module.scss'

function FormInput({ placeholder, field, multiline = false }) {
  const { touched, values, errors, handleChange, handleBlur } = useFormikContext()

  const handleInputChange = (event) => {
    const { target } = event
    target.style.height = 'auto'
    target.style.height = `${target.scrollHeight}px`

    handleChange(event)
  }

  return (
    <div className={s.wrapper}>
      {multiline ? (
        <textarea
          className={clsx(
            s.input,
            s.resizeNone,
            touched[field] && Boolean(errors[field]) && s.inputError
          )}
          placeholder={placeholder}
          id={field}
          value={values[field]}
          onChange={handleInputChange}
          name={field}
          onBlur={handleBlur}
          rows="1"
        />
      ) : (
        <input
          className={clsx(s.input, touched[field] && Boolean(errors[field]) && s.inputError)}
          placeholder={placeholder}
          id={field}
          value={values[field]}
          onChange={handleChange}
          name={field}
          onBlur={handleBlur}
        />
      )}

      {touched[field] && <p className={s.error}>{errors[field]}</p>}
    </div>
  )
}

export default observer(FormInput)
