import clsx from 'clsx'
import { observer } from 'mobx-react-lite'

import s from './Styles.module.scss'

function Category({ name, isActive, onClick }) {
  return (
    <button onClick={onClick} className={clsx(s.category, isActive && s.colorActive)}>
      {name}
    </button>
  )
}

export default observer(Category)
