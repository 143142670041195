import { useRef } from 'react'
import clsx from 'clsx'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { Link, generatePath } from 'react-router-dom'

import { ROUTES } from 'shared/consts'
import arrowLeft from 'shared/assets/icons/arrows/arrow-left.svg'
import arrowRight from 'shared/assets/icons/arrows/arrow-right-violet-second.svg'

import s from './Styles.module.scss'
import './Styles.module.scss'

function MayBeInterested({ product }) {
  const slider = useRef(null)

  function slideToShow() {
    if (product.recommendedProductSet?.length === 1) {
      return 1
    } else if (product.recommendedProductSet?.length === 2) {
      return 2
    } else {
      return 3
    }
  }

  return (
    <div className={s.wrapper}>
      <div className={s.subTitleWrapper}>
        <p className={s.subTitle}>подборка товаров для вас</p>
        <div className={s.subTitleLine}></div>
      </div>

      <div className={s.sliderWrapper}>
        <Slider
          ref={slider}
          speed={500}
          autoplay
          slidesToShow={slideToShow()}
          slidesToScroll={slideToShow()}
          arrows={false}
          className={clsx(product.recommendedProductSet?.length < 4 && s.slider)}
        >
          {product.recommendedProductSet?.map((recommendedProduct) => (
            <Link
              to={generatePath(ROUTES.EQUIPMENT, { id: String(recommendedProduct.id) })}
              key={recommendedProduct.id}
              className={clsx(
                slideToShow() === 3 ? s.cursorGrab : s.cursorDefault,
                s.productWrapper
              )}
            >
              <img src={recommendedProduct.mainPictureUrl.url} alt="Рекомендованный продукт" />
              <p>{recommendedProduct.name}</p>
            </Link>
          ))}
        </Slider>

        <div className={s.slideButtons}>
          <button onClick={() => slider?.current?.slickPrev()}>
            <img src={arrowLeft} alt="Arrow" />
          </button>
          <button onClick={() => slider?.current?.slickNext()}>
            <img src={arrowRight} alt="Arrow" />
          </button>
        </div>
      </div>

      <div className={s.sliderMobileWrapper}>
        <Slider dots speed={500} slidesToShow={1} slidesToScroll={1} arrows={false}>
          {product.recommendedProductSet?.map((recommendedProduct) => (
            <Link
              to={generatePath(ROUTES.EQUIPMENT, { id: String(recommendedProduct.id) })}
              key={recommendedProduct.id}
              className={clsx(
                slideToShow() === 3 ? s.cursorGrab : s.cursorDefault,
                s.productWrapper
              )}
            >
              <img src={recommendedProduct.mainPictureUrl.url} alt="Рекомендованный продукт" />
              <p>{recommendedProduct.name}</p>
            </Link>
          ))}
        </Slider>
      </div>
    </div>
  )
}

export default MayBeInterested
