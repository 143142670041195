import Slider from 'react-slick'

import { LinkButton } from 'shared/ui'
import { scrollToForm } from 'shared/helpers'

import share from 'shared/assets/icons/share.svg'
import team1 from 'shared/assets/img/About-company/about-team-1.webp'
import team2 from 'shared/assets/img/About-company/about-team-2(2).jpg'
import team3 from 'shared/assets/img/About-company/about-team-3.jpg'
import team4 from 'shared/assets/img/About-company/about-team-44.jpg'

import s from './Styles.module.scss'

function OurTeam() {
  return (
    <div className={s.wrapper}>
      <div className={s.subTiltleWrapper}>
        <p className={s.subTitle}>команда профессионалов</p>
        <div className={s.subTitleLine}></div>
      </div>

      <div className={s.titleWrapper}>
        <div className={s.infoWrapper}>
          <h1>наша команда</h1>

          <div className={s.buttonWrapper}>
            <LinkButton
              onClick={scrollToForm}
              text="Начать сотрудничество"
              icon={share}
              alt="Поделиться"
              theme="green"
            />
          </div>
        </div>
        <p>
          Наши специалисты-технологи помогут Вам с выбором наиболее подходящего решения для
          производства. А конструкторы, инженеры и IT отдел превратят Ваши запросы и требования в
          оборудование, отвечающее самым высоким стандартам.
        </p>
        <div className={s.mobileLinkWrapper}>
          <LinkButton
            onClick={scrollToForm}
            text="Начать сотрудничество"
            icon={share}
            alt="Поделиться"
            theme="green"
          />
        </div>
      </div>

      <div className={s.wrap}>
        <div className={s.teamWrapper}>
          <div className={s.team1}></div>
          <p>Технологи</p>
        </div>

        <div className={s.teamWrapper}>
          <div className={s.team3}></div>
          <p>Инженеры и конструкторы</p>
        </div>

        <div className={s.teamWrapper}>
          <div className={s.team2}></div>
          <p>ПРОИЗВОДСТВЕННАЯ КОМАНДА</p>
        </div>

        <div className={s.teamWrapper}>
          <div className={s.team4}></div>
          <p>IT ОТДЕЛ</p>
        </div>
      </div>

      <div className={s.sliderMobileWrapper}>
        <Slider dots speed={500} slidesToShow={1} slidesToScroll={1} arrows={false}>
          <div className={s.mobileTeamWrapper}>
            <img src={team1} alt="Team" />
            <p>Технологи</p>
          </div>

          <div className={s.mobileTeamWrapper}>
            <img src={team3} alt="Team" />
            <p>Инженеры и конструкторы</p>
          </div>

          <div className={s.mobileTeamWrapper}>
            <img src={team2} alt="Team" />
            <p>ПРОИЗВОДСТВЕННАЯ КОМАНДА</p>
          </div>

          <div className={s.mobileTeamWrapper}>
            <img src={team4} alt="Team" />
            <p>IT ОТДЕЛ</p>
          </div>
        </Slider>
      </div>
    </div>
  )
}

export default OurTeam
