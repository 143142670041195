import qs from 'qs'

export const getQueryParams = (obj) => {
  const result = {}

  Object.entries(obj).forEach(([key, value]) => {
    if (value || value > 0) {
      result[key] = value
    }
  })

  return qs.stringify(result, { addQueryPrefix: true, arrayFormat: 'repeat' })
}

export const getSearchParamsObj = (searchParams) => {
  const params = {}

  searchParams.forEach((value, key) => {
    params[key] = value
  })

  return params
}
