import { useRef } from 'react'
import { Helmet } from 'react-helmet'

import { Form } from 'entities/Form'
import { Footer, Header } from 'widgets'
import HeaderSticky from 'widgets/Header/HeaderSticky'

import s from './Styles.module.scss'
import { ContactInformation } from './ui'
import { useEffect } from 'react'

function Contacts() {
  const containerRef = useRef(null)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Helmet>
        <title>Контакты</title>
      </Helmet>

      <HeaderSticky containerRef={containerRef} />
      <div className={s.headerWrapper}>
        <Header containerRef={containerRef} />
      </div>
      <div className={s.container}>
        <div className={s.mobileWrapper}>
          <ContactInformation />
          <Form />
        </div>
        <Footer />
      </div>
    </>
  )
}
export default Contacts
