import clsx from 'clsx'

import s from './Styles.module.scss'

function Specifications({ product }) {
  return (
    <div className={s.wrapper}>
      <div className={s.subTiltleWrapper}>
        <p className={s.subTitle}>Технические характеристики</p>
        <div className={s.subTitleLine}></div>
      </div>

      {product.technicalCharacteristics?.map((characteristic) => (
        <div key={characteristic.id}>
          <div className={s.textWrapper}>
            <div className={clsx(s.text, s.colorViolet, s.maxWidth)}>{characteristic.name}</div>
            <div className={clsx(s.text, s.colorDark)}>{characteristic.description}</div>
          </div>

          <div className={s.divider}></div>
        </div>
      ))}
    </div>
  )
}

export default Specifications
