import { useEffect, useRef } from 'react'
import { Helmet } from 'react-helmet'
import { observer } from 'mobx-react-lite'

import { BreadCrumbs, LinkButton } from 'shared/ui'
import { Footer, Header } from 'widgets'
import { Form } from 'entities/Form'
import sms from 'shared/assets/icons/sms-tracking.svg'
import { scrollToForm } from 'shared/helpers'
import HeaderSticky from 'widgets/Header/HeaderSticky'

import { OurProjects } from './ui'
import s from './Styles.module.scss'
import { PortfoliosModel } from './model'

function Portfolios() {
  const containerRef = useRef(null)

  useEffect(() => {
    PortfoliosModel.fetch()
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Helmet>
        <title>Портфолио</title>
      </Helmet>

      <HeaderSticky containerRef={containerRef} />
      <div>
        <div className={s.headerWrapper}>
          <Header containerRef={containerRef} />

          <div className={s.bgWrapper}>
            <BreadCrumbs name="Портфолио" />

            <div className={s.textWrapper}>
              <h2 className={s.title}>Наши проекты</h2>
              <div className={s.line}></div>
              <p className={s.text}>
                Мы искренне благодарим всех наших заказчиков за выбор нас в качестве поставщиков. Мы
                ценим Ваше доверие и стремимся предоставлять лучший сервис и качественные услуги.
                Надеемся на долгосрочное и плодотворное сотрудничество!
              </p>
            </div>
          </div>

          <div className={s.mobileLink}>
            <LinkButton
              text="Оставить запрос"
              icon={sms}
              alt="sms"
              onClick={scrollToForm}
              theme="green"
            />
          </div>
        </div>
        <div className={s.container}>
          <OurProjects projects={PortfoliosModel.projects} />

          <div className={s.wrap}>
            <Form />
            <Footer />
          </div>
        </div>
      </div>
    </>
  )
}

export default observer(Portfolios)
