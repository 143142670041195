import clsx from 'clsx'
import { observer } from 'mobx-react-lite'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'

import { Spinner } from 'shared/ui'
import { ROUTES } from 'shared/consts'
import { WidgetsModel } from 'widgets/model'
import logoDark from 'shared/assets/img/LogoDark.svg'

import s from './Styles.module.scss'

function Footer() {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()

  return (
    <footer className={s.wrapper}>
      <div className={s.footerWrapper}>
        <div className={s.column}>
          <Link to={ROUTES.MAIN} className={s.title}>
            <img src={logoDark} alt="Logo" />
          </Link>

          <div className={s.infoWrapper}>
            <div className={s.wrapperMobile}>
              <a href="tel:+375173000081" className={s.infoLink}>
                +375 17 300-00-81
              </a>
              <a href="tel:+74997033340" className={s.infoLink}>
                +7 499 703-33-40
              </a>
            </div>
            <a href="mailto:sales@mirotec.by" className={s.infoLink}>
              sales@mirotec.by
            </a>
          </div>
        </div>

        <div className={s.column}>
          <div className={s.chapterTitle}>Оборудование</div>

          <div className={s.chapterWrapper}>
            {WidgetsModel.loading.has ? (
              <Spinner />
            ) : (
              <>
                {WidgetsModel.categoriesWithProducts?.map((category) => (
                  <p
                    key={category.id}
                    onClick={() => {
                      const newSearchParams = new URLSearchParams(searchParams.toString())

                      navigate(ROUTES.EQUIPMENTS)
                      window.scrollTo(0, 0)

                      newSearchParams.set('category', category.id)
                      newSearchParams.set('page', 0)
                      setSearchParams(newSearchParams)
                    }}
                    className={s.chapterLink}
                  >
                    {category.name}
                  </p>
                ))}
              </>
            )}
          </div>
        </div>

        <div className={s.column}>
          <div
            onClick={() => {
              navigate(ROUTES.ENGINEERING)
              window.scrollTo(0, 0)
            }}
            className={s.chapterTitle}
          >
            Инжиниринг
          </div>

          <div className={s.chapterWrapper}>
            {/* <a href="#a" className={s.chapterLink}>
              Название раздела 1
            </a>
            <a href="#a" className={s.chapterLink}>
              Название раздела 2
            </a>
            <a href="#a" className={s.chapterLink}>
              Название раздела 3
            </a>
            <a href="#a" className={s.chapterLink}>
              Название раздела 4
            </a> */}
          </div>
        </div>

        <div className={s.column}>
          <div
            onClick={() => {
              navigate(ROUTES.PORTFOLIOS)
              window.scrollTo(0, 0)
            }}
            className={s.chapterTitle}
          >
            Портфолио
          </div>

          <div className={s.chapterWrapper}>
            {/* <a href="#a" className={s.chapterLink}>
              Название раздела 1
            </a>
            <a href="#a" className={s.chapterLink}>
              Название раздела 2
            </a>
            <a href="#a" className={s.chapterLink}>
              Название раздела 3
            </a>
            <a href="#a" className={s.chapterLink}>
              Название раздела 4
            </a> */}
          </div>
        </div>

        <div className={s.column}>
          <div
            onClick={() => {
              navigate(ROUTES.ABOUT_US)
              window.scrollTo(0, 0)
            }}
            className={s.chapterTitle}
          >
            О нас
          </div>

          <div className={s.chapterWrapper}>
            {/* <a href="#a" className={s.chapterLink}>
              Название раздела 1
            </a>
            <a href="#a" className={s.chapterLink}>
              Название раздела 2
            </a>
            <a href="#a" className={s.chapterLink}>
              Название раздела 3
            </a>
            <a href="#a" className={s.chapterLink}>
              Название раздела 4
            </a> */}
          </div>
        </div>

        <div className={s.column}>
          <div
            onClick={() => {
              navigate(ROUTES.CONTACTS)
              window.scrollTo(0, 0)
            }}
            className={s.chapterTitle}
          >
            Контакты
          </div>

          <div className={s.chapterWrapper}>
            {/* <a href="#a" className={s.chapterLink}>
              Название раздела 1
            </a>
            <a href="#a" className={s.chapterLink}>
              Название раздела 2
            </a>
            <a href="#a" className={s.chapterLink}>
              Название раздела 3
            </a>
            <a href="#a" className={s.chapterLink}>
              Название раздела 4
            </a> */}
          </div>
        </div>
      </div>

      <div className={s.divider}></div>

      <div className={s.privacyBlockWrapper}>
        <p className={clsx(s.alignCenter)}>© 2024 ОДО "Мироздание"</p>
        <div className={s.privacyWrapper}></div>
      </div>
    </footer>
  )
}

export default observer(Footer)
