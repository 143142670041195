import { makeAutoObservable } from 'mobx'
import LoadingModel from 'models/Loading'

import { API } from 'services'

class PortfolioModel {
  portfolio = {}

  loading = LoadingModel

  constructor() {
    makeAutoObservable(this)

    this.loading = new LoadingModel()
  }

  async fetch(id) {
    try {
      this.loading.begin()

      const data = await API.projects.getById(id)

      this.portfolio = data
    } catch (err) {
      console.log(err)
    } finally {
      this.loading.reset()
    }
  }
}

const model = new PortfolioModel()

export default model
