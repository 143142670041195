import { Link } from 'react-router-dom'

import { ROUTES } from 'shared/consts'
import arrowLeft from 'shared/assets/icons/arrows/arrow-left-blue.svg'
import arrowLeftGrey from 'shared/assets/icons/arrows/arrow-left-grey.svg'

import s from './Styles.module.scss'

function BreadCrumbs({ name, secondName, to, theme, isShowMainPageActive = true, onClick }) {
  return (
    <div className={s.wrapper}>
      {isShowMainPageActive && (
        <>
          <Link to={ROUTES.MAIN} className={theme === 'dark' ? s.mainCrumbDark : s.mainCrumb}>
            Главная страница
          </Link>

          {theme === 'dark' ? (
            <img src={arrowLeftGrey} alt="Arrow left" />
          ) : (
            <img src={arrowLeft} alt="Arrow left" />
          )}
        </>
      )}
      {to ? (
        <Link
          to={secondName && to}
          className={theme === 'dark' ? (secondName ? s.mainCrumbDark : s.secondCrumb) : s.crumb}
        >
          {name}
        </Link>
      ) : onClick ? (
        <Link onClick={onClick} className={s.mainCrumbDark}>
          {name}
        </Link>
      ) : (
        <p to={ROUTES.MAIN} className={theme === 'dark' ? s.crumbDark : s.crumb}>
          {name}
        </p>
      )}

      {secondName && (
        <>
          {theme === 'dark' ? (
            <img src={arrowLeftGrey} alt="Arrow left" />
          ) : (
            <img src={arrowLeft} alt="Arrow left" />
          )}
          <p className={s.secondCrumb}>{secondName}</p>
        </>
      )}
    </div>
  )
}

export default BreadCrumbs
