import { observer } from 'mobx-react-lite'
import { useSearchParams } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroll-component'
import clsx from 'clsx'

import { Spinner } from 'shared/ui'

import s from './Styles.module.scss'
import { Category, Product } from './ui'
import { EquipmentsModel } from '../../model'

function Products() {
  const [searchParams, setSearchParams] = useSearchParams()

  const handleCategoryChange = (id) => {
    const newSearchParams = new URLSearchParams(searchParams.toString())

    if (Boolean(id) === false || searchParams.get('subcategory') === String(id)) {
      newSearchParams.delete('subcategory')
      newSearchParams.set('page', 0)
      EquipmentsModel.clearProductsModel()
    } else {
      newSearchParams.set('subcategory', id)
      newSearchParams.set('page', 0)
      EquipmentsModel.clearProductsModel()
    }

    setSearchParams(newSearchParams)
  }

  return (
    <div className={s.wrapper}>
      <h6 className={s.title}>Товары</h6>
      <div className={s.categoriesWrapper}>
        <Category
          name="Все товары"
          isActive={!searchParams.get('subcategory')}
          onClick={() => (!searchParams.get('subcategory') ? null : handleCategoryChange())}
        />
        {EquipmentsModel.categories.subcategoryList?.map((subcategory) => (
          <Category
            key={subcategory.id}
            name={subcategory.name}
            isActive={searchParams.get('subcategory') === String(subcategory.id)}
            onClick={() => handleCategoryChange(subcategory.id)}
            s
          />
        ))}
      </div>

      <select
        onChange={(e) => handleCategoryChange(e.target.value)}
        value={searchParams.get('subcategory')}
        className={s.selectCategoryMobile}
      >
        <option value={''}>Все товары</option>
        {EquipmentsModel.categories.subcategoryList?.map((subcategory) => (
          <option key={subcategory.id} value={String(subcategory.id)}>
            {subcategory.name}
          </option>
        ))}
      </select>

      <InfiniteScroll
        dataLength={EquipmentsModel.products.length}
        next={() => {
          const newSearchParams = new URLSearchParams(searchParams.toString())
          newSearchParams.set('page', Number(searchParams.get('page')) + 1)

          setSearchParams(newSearchParams)
        }}
        hasMore={EquipmentsModel.isServerHasMoreProducts}
        loader={<Spinner />}
        className={clsx(
          s.productsWrapper,
          EquipmentsModel.products.length === 2 && s.justifyContent
        )}
      >
        {EquipmentsModel.products?.map((product) => (
          <Product
            key={product.id}
            id={product.id}
            name={product.name}
            imgUrl={product.mainPictureUrl.url}
            description={product.description}
          />
        ))}
      </InfiniteScroll>
    </div>
  )
}

export default observer(Products)
