import axios from 'axios'

const ENDPOINT_BASE = '/subcategories'
const { REACT_APP_BASE_URL } = process.env

export const get = async () => {
  const response = await axios(`${REACT_APP_BASE_URL}${ENDPOINT_BASE}`)
  return response.data
}

export const getById = async (id) => {
  const response = await axios(`${REACT_APP_BASE_URL}${ENDPOINT_BASE}/${id}`)
  return response.data
}
