import { useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import Slider from 'react-slick'

import { BreadCrumbs } from 'shared/ui'
import { ROUTES } from 'shared/consts'

import s from './Styles.module.scss'

function ProjectInfo({ portfolio }) {
  const descriptionRef = useRef(null)

  useEffect(() => {
    descriptionRef.current.innerHTML = portfolio.description
  }, [portfolio])

  return (
    <div className={s.wrapper}>
      <BreadCrumbs
        name="Портфолио"
        secondName={portfolio.name}
        theme="dark"
        to={ROUTES.PORTFOLIOS}
        isShowMainPageActive={false}
      />

      <div className={s.infoWrapper}>
        <div className={s.textWrapper}>
          <h5>{portfolio.name}</h5>
          <p ref={descriptionRef}></p>

          <div className={s.wrapperImgs}>
            <div>
              {portfolio.picturesUrls && portfolio.picturesUrls[1] && (
                <img src={portfolio?.picturesUrls[1].url} alt="Machine" />
              )}
              {portfolio.picturesUrls && portfolio.picturesUrls[2] && (
                <img src={portfolio?.picturesUrls[2].url} alt="Machine" />
              )}
            </div>
            {portfolio.picturesUrls && portfolio.picturesUrls[3] && (
              <div>
                {portfolio.picturesUrls && portfolio.picturesUrls[3] && (
                  <img src={portfolio?.picturesUrls[3].url} alt="Machine" />
                )}
                {portfolio.picturesUrls && portfolio.picturesUrls[4] && (
                  <img src={portfolio?.picturesUrls[4].url} alt="Machine" />
                )}
              </div>
            )}
          </div>
        </div>

        <img src={portfolio.picturesUrls && portfolio.picturesUrls[0].url} alt="Product" />

        <div className={s.sliderMobileWrapper}>
          <Slider dots speed={500} slidesToShow={1} slidesToScroll={1} arrows={false}>
            {portfolio.picturesUrls?.map((img) => (
              <img key={img.id} src={img.url} alt="Portfolio cover" />
            ))}
          </Slider>
        </div>
      </div>
    </div>
  )
}

export default observer(ProjectInfo)
