import { Formik } from 'formik'
import * as Yup from 'yup'
import { observer } from 'mobx-react-lite'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import mechanism from 'shared/assets/img/mechanism.png'
import send from 'shared/assets/icons/send.svg'

import { FormInput } from './ui'
import s from './Styles.module.scss'
import { FormModel } from './model'

function Form() {
  const validationSchema = Yup.object().shape({
    name: Yup.string().min(3, 'Too Short!').max(50, 'Too Long!').required('Обязательное поле!'),
    company: Yup.string().min(3, 'Too Short!').max(50, 'Too Long!').required('Обязательное поле!'),
    email: Yup.string().email('Неправильный email адрес!').required('Обязательное поле!'),
    phone: Yup.string().required('Обязательное поле!')
  })

  return (
    <Formik
      initialValues={{
        name: '',
        company: '',
        email: '',
        phone: '',
        message: '',
        pageLink: `${window.location.href}`
      }}
      validationSchema={validationSchema}
      onSubmit={(values, { resetForm }) => {
        FormModel.sendRequest(values)
        resetForm()
        const textarea = document.querySelector('textarea[name="message"]')
        textarea.style.height = 'auto'

        toast('Запрос отправлен', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light'
        })
      }}
    >
      {({ handleSubmit }) => (
        <div>
          <ToastContainer />
          <form onSubmit={handleSubmit} id="form" className={s.wrapper}>
            <img src={mechanism} className={s.imgMobile} alt="Mechanism" />
            <div className={s.textWrapper}>
              <div className={s.subTiltleWrapper}>
                <p className={s.subTitle}>Обратная связь</p>
                <div className={s.subTitleLine}></div>
              </div>
              <h1 className={s.title}>
                Оставьте
                <br /> свой запрос
              </h1>

              <p className={s.text}>
                Наш специалист свяжется с Вами в ближайшее время для консультации по всем возникшим
                вопросам.
              </p>
              <img src={mechanism} className={s.img} alt="Mechanism" />
            </div>
            <div className={s.formWrapper}>
              <FormInput placeholder="Введите Ваше имя*" field="name" />
              <FormInput placeholder="Введите название компании*" field="company" />
              <FormInput placeholder="Введите адрес электронной почты*" field="email" />
              <FormInput placeholder="Введите номер телефона*" field="phone" />
              <FormInput
                placeholder="Введите Ваш запрос (до 500 символов)"
                field="message"
                multiline
              />
              <button type="submit" className={s.button}>
                Отправить запрос <img src={send} alt="Send" />
              </button>
            </div>
          </form>
        </div>
      )}
    </Formik>
  )
}

export default observer(Form)
