import React from 'react'
import { observer } from 'mobx-react-lite'

import Router from './Router'

function App() {
  return <Router />
}

export default observer(App)
