import axios from 'axios'
import { getQueryParams } from 'shared/helpers'

const ENDPOINT_BASE = '/products'
const { REACT_APP_BASE_URL } = process.env

export const get = async (searchParams) => {
  const response = await axios(
    `${REACT_APP_BASE_URL}${ENDPOINT_BASE}${getQueryParams({
      ...searchParams
    })}`
  )
  return response.data
}

export const getById = async (id) => {
  const response = await axios(`${REACT_APP_BASE_URL}${ENDPOINT_BASE}/${id}`)
  return response.data
}
