import clsx from 'clsx'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { LinkButton } from 'shared/ui'
import { ROUTES } from 'shared/consts'
import { scrollToForm } from 'shared/helpers'
import { WidgetsModel } from 'widgets/model'

import logoDark from 'shared/assets/img/LogoDark.svg'

import sms from 'shared/assets/icons/sms-tracking-violet.svg'

import s from './Styles.module.scss'
import { EquipmentsMenu } from './ui'

function HeaderSticky({ containerRef }) {
  const [isHeaderTransparent, setIsHeaderTransparent] = useState(false)

  function scrollHeight() {
    setIsHeaderTransparent(
      window.scrollY >
        Number(
          containerRef.current &&
            containerRef.current.getBoundingClientRect().height +
              Number(
                containerRef.current &&
                  (containerRef.current.getBoundingClientRect().height * 21) / 100
              )
        )
    )
  }

  useEffect(() => {
    WidgetsModel.fetch()
    setIsHeaderTransparent(window.scrollY > 1)
    window.addEventListener('scroll', scrollHeight)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <header className={clsx(s.wrapperSticky, !isHeaderTransparent && s.fade)}>
        <p className={s.textSticky}>
          <a className={s.phoneLink} href="tel:+375173000081">
            BY +375 17 300-00-81
          </a>{' '}
          |
          <a className={s.phoneLink} href="tel:+74997033340">
            RUS +7 499 703-33-40
          </a>
        </p>
        <div className={s.navWrapper} style={{ display: !isHeaderTransparent ? 'none' : 'flex' }}>
          <Link to={ROUTES.MAIN} className={s.title}>
            <img src={logoDark} alt="Logo" />
          </Link>

          <nav className={s.nav}>
            <div className={clsx(isHeaderTransparent && s.menuButtonWrapper)}>
              <div className={clsx(s.linkSticky)}>Оборудование</div>
              <div className={s.equipmentsMenuWrapper}>
                <EquipmentsMenu />
              </div>
            </div>
            <Link
              to={ROUTES.ENGINEERING}
              onClick={(e) => e.stopPropagation()}
              className={clsx(s.linkSticky)}
            >
              Инжиниринг
            </Link>
            <Link
              to={ROUTES.PORTFOLIOS}
              onClick={(e) => e.stopPropagation()}
              className={clsx(s.linkSticky)}
            >
              Портфолио
            </Link>
            <Link
              to={ROUTES.ABOUT_US}
              onClick={(e) => e.stopPropagation()}
              className={clsx(s.linkSticky)}
            >
              О нас
            </Link>
            <Link
              to={ROUTES.CONTACTS}
              onClick={(e) => e.stopPropagation()}
              className={clsx(s.linkSticky)}
            >
              Контакты
            </Link>
          </nav>

          <LinkButton
            text="Оставить запрос"
            icon={sms}
            alt="sms"
            onClick={scrollToForm}
            theme={'violet'}
          />
        </div>
      </header>
    </>
  )
}

export default observer(HeaderSticky)
