import { makeAutoObservable } from 'mobx'

import LoadingModel from 'models/Loading'

import { API } from 'services'

class WidgetsModel {
  categoriesWithProducts = []

  loading = LoadingModel

  constructor() {
    makeAutoObservable(this)

    this.loading = new LoadingModel()
  }

  async fetch() {
    try {
      this.loading.begin()

      const data = await API.categories.getWithProducts()

      this.categoriesWithProducts = data
    } catch (err) {
      console.log(err)
    } finally {
      this.loading.reset()
    }
  }
}

const model = new WidgetsModel()

export default model
