import clsx from 'clsx'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'

import { LinkButton, Spinner } from 'shared/ui'
import { ROUTES } from 'shared/consts'
import { scrollToForm } from 'shared/helpers'
import { WidgetsModel } from 'widgets/model'

import logo from 'shared/assets/img/Logo.svg'
import logoDark from 'shared/assets/img/LogoDark.svg'
import logoMobile from 'shared/assets/img/LogoMobile.png'

import sms from 'shared/assets/icons/sms-tracking.svg'
import menu from 'shared/assets/icons/menu.svg'
import menuBlack from 'shared/assets/icons/menu-black.svg'
import close from 'shared/assets/icons/close.svg'
import arrowLeft from 'shared/assets/icons/arrows/arrow-left-black.svg'

import s from './Styles.module.scss'
import { EquipmentMobile, EquipmentsMenu } from './ui'

function Header({ containerRef }) {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)
  const [isEquipmentOpen, setIsEquipmentOpen] = useState(false)

  const equipmentRouteRegex = /^\/equipment\/\d+$/
  const portfolioRouteRegex = /^\/portfolio\/\d+$/

  useEffect(() => {
    WidgetsModel.fetch()
  }, [])

  useEffect(() => {
    if (isMobileMenuOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'scroll'
    }
  }, [isMobileMenuOpen])

  return (
    <>
      <header ref={containerRef} className={s.wrapper}>
        <p className={pathname === ROUTES.MAIN ? s.text : s.textDark}>
          <a href="tel:+375173000081" onClick={(e) => e.stopPropagation()}>
            BY +375 17 300-00-81
          </a>
          |
          <a href="tel:+74997033340" onClick={(e) => e.stopPropagation()}>
            RUS +7 499 703-33-40
          </a>
        </p>
        <div className={s.navWrapper}>
          <div
            onClick={(e) => {
              e.stopPropagation()
            }}
          >
            <Link to={ROUTES.MAIN} className={s.title}>
              <img src={pathname === ROUTES.MAIN ? logo : logoDark} alt="Logo" />
            </Link>
          </div>

          <nav className={s.nav}>
            <div className={s.menuButtonWrapper}>
              <div
                className={clsx(
                  pathname === ROUTES.EQUIPMENTS
                    ? s.linkActive
                    : pathname === ROUTES.MAIN
                    ? s.link
                    : s.linkDark,
                  pathname === ROUTES.EQUIPMENT
                    ? s.linkActive
                    : pathname === ROUTES.MAIN
                    ? s.link
                    : s.linkDark,
                  s.equipmentsHover
                )}
              >
                Оборудование
              </div>

              <div className={s.equipmentsMenuWrapper}>
                <EquipmentsMenu />
              </div>
            </div>
            <Link
              to={ROUTES.ENGINEERING}
              onClick={(e) => e.stopPropagation()}
              className={clsx(
                pathname === ROUTES.ENGINEERING
                  ? s.linkActive
                  : pathname === ROUTES.MAIN
                  ? s.link
                  : s.linkDark
              )}
            >
              Инжиниринг
            </Link>
            <Link
              to={ROUTES.PORTFOLIOS}
              onClick={(e) => e.stopPropagation()}
              className={clsx(
                pathname === ROUTES.PORTFOLIOS
                  ? s.linkActive
                  : pathname === ROUTES.MAIN
                  ? s.link
                  : s.linkDark,
                pathname === ROUTES.PORTFOLIO
                  ? s.linkActive
                  : pathname === ROUTES.MAIN
                  ? s.link
                  : s.linkDark
              )}
            >
              Портфолио
            </Link>
            <Link
              to={ROUTES.ABOUT_US}
              onClick={(e) => e.stopPropagation()}
              className={clsx(
                pathname === ROUTES.ABOUT_US
                  ? s.linkActive
                  : pathname === ROUTES.MAIN
                  ? s.link
                  : s.linkDark
              )}
            >
              О нас
            </Link>
            <Link
              to={ROUTES.CONTACTS}
              onClick={(e) => e.stopPropagation()}
              className={clsx(
                pathname === ROUTES.CONTACTS
                  ? s.linkActive
                  : pathname === ROUTES.MAIN
                  ? s.link
                  : s.linkDark
              )}
            >
              Контакты
            </Link>
          </nav>

          <LinkButton
            text="Оставить запрос"
            icon={sms}
            alt="sms"
            onClick={scrollToForm}
            theme={pathname !== ROUTES.MAIN && 'green'}
          />
        </div>
      </header>

      <header className={s.mobileWrapper}>
        <div
          onClick={(e) => {
            e.stopPropagation()
          }}
        >
          <Link to={ROUTES.MAIN} className={s.title}>
            <img src={pathname === ROUTES.MAIN ? logoMobile : logoDark} alt="Logo" />
          </Link>
        </div>
        <button
          onClick={(e) => {
            e.stopPropagation()
            setIsMobileMenuOpen(!isMobileMenuOpen)
          }}
          className={s.menuButton}
        >
          <img
            src={
              pathname === ROUTES.EQUIPMENTS ||
              equipmentRouteRegex.test(pathname) ||
              pathname === ROUTES.ENGINEERING ||
              pathname === ROUTES.PORTFOLIOS ||
              portfolioRouteRegex.test(pathname) ||
              pathname === ROUTES.ABOUT_US ||
              pathname === ROUTES.CONTACTS
                ? menuBlack
                : menu
            }
            alt="Menu"
          />
        </button>

        <div
          onClick={(e) => e.stopPropagation()}
          className={clsx(
            isEquipmentOpen ? s.menuMobileWrapperWithOpenEquipment : s.menuMobileWrapper,
            isMobileMenuOpen && s.open
          )}
        >
          <div className={s.menuMobileHeader}>
            <Link
              onClick={() => {
                setIsMobileMenuOpen(!isMobileMenuOpen)
                navigate(ROUTES.MAIN)
              }}
              className={s.title}
            >
              <img src={logoDark} alt="Logo" />
            </Link>
            <button
              onClick={() =>
                isEquipmentOpen ? setIsEquipmentOpen(false) : setIsMobileMenuOpen(!isMobileMenuOpen)
              }
              className={s.menuButton}
            >
              {isEquipmentOpen ? (
                <img src={arrowLeft} alt="Arrow left" />
              ) : (
                <img src={close} alt="Close" />
              )}
            </button>
          </div>

          <nav className={clsx(s.menuMobileNav, isEquipmentOpen && s.displayNone)}>
            <Link
              onClick={() => {
                setIsEquipmentOpen(true)
              }}
            >
              Оборудование
            </Link>

            <div className={s.divider}></div>

            <Link
              onClick={() => {
                setIsMobileMenuOpen(!isMobileMenuOpen)
                navigate(ROUTES.ENGINEERING)
              }}
            >
              Инжиниринг
            </Link>

            <div className={s.divider}></div>

            <Link
              onClick={() => {
                setIsMobileMenuOpen(!isMobileMenuOpen)
                navigate(ROUTES.PORTFOLIOS)
              }}
            >
              Портфолио
            </Link>

            <div className={s.divider}></div>

            <Link
              onClick={() => {
                setIsMobileMenuOpen(!isMobileMenuOpen)
                navigate(ROUTES.ABOUT_US)
              }}
            >
              О нас
            </Link>

            <div className={s.divider}></div>

            <Link
              onClick={() => {
                setIsMobileMenuOpen(!isMobileMenuOpen)
                navigate(ROUTES.CONTACTS)
              }}
            >
              Контакты
            </Link>
          </nav>

          <nav className={clsx(s.equipmentNavWrapper, isEquipmentOpen && s.displayFlex)}>
            {WidgetsModel.loading.has ? (
              <Spinner />
            ) : (
              WidgetsModel.categoriesWithProducts.map((categorieWithProduct) => (
                <div key={categorieWithProduct.id}>
                  <EquipmentMobile
                    setIsMobileMenuOpen={setIsMobileMenuOpen}
                    categorieWithProduct={categorieWithProduct}
                  />

                  <div className={s.divider}></div>
                </div>
              ))
            )}
          </nav>

          <div className={clsx(s.menuMobileFooter, isEquipmentOpen && s.justifySelfEnd)}>
            <div className={s.phoneNumbers}>
              <a href="tel:+375173000081">BY | +375 17 300-00-81</a>
              <a href="tel:+74997033340">RUS | +7 499 703-33-40</a>
            </div>
            <div className={s.menuMobileButtonWrapper}>
              <LinkButton
                onClick={(e) => {
                  e.stopPropagation()
                  setIsMobileMenuOpen(!isMobileMenuOpen)
                  scrollToForm()
                }}
                text="Оставить запрос"
                icon={sms}
                alt="sms"
                theme="green"
              />
            </div>
          </div>
        </div>
      </header>
    </>
  )
}

export default observer(Header)
