import { useEffect, useRef } from 'react'
import { Helmet } from 'react-helmet'
import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router-dom'

import { Spinner } from 'shared/ui'
import { Form } from 'entities/Form'
import { Footer, Header } from 'widgets'
import HeaderSticky from 'widgets/Header/HeaderSticky'

import s from './Styles.module.scss'
import { EquipmentModel } from './model'
import { EquipmentInfo, MayBeInterested, Specifications } from './ui'

function Equipment() {
  const { id } = useParams()
  const containerRef = useRef(null)

  useEffect(() => {
    EquipmentModel.fetch(Number(id))
  }, [id])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Helmet>
        <title>{EquipmentModel.product.name ?? 'Продукт'}</title>
      </Helmet>

      <HeaderSticky containerRef={containerRef} />
      <div className={s.headerWrapper}>
        <Header containerRef={containerRef} />
      </div>
      {EquipmentModel.loading.has ? (
        <Spinner />
      ) : (
        <>
          <div className={s.container}>
            <EquipmentInfo product={EquipmentModel.product} />
            {EquipmentModel.product.technicalCharacteristics?.length > 0 && (
              <Specifications product={EquipmentModel.product} />
            )}
          </div>
          {EquipmentModel.product.recommendedProductSet?.length > 0 && (
            <div className={s.MayBeInterestedWrapper}>
              <MayBeInterested product={EquipmentModel.product} />
            </div>
          )}
          <div className={s.containerSecond}>
            <Form />
            <Footer />
          </div>
        </>
      )}
    </>
  )
}

export default observer(Equipment)
