import * as subcategories from './subcategories'
import * as categories from './categories'
import * as projects from './projects'
import * as products from './products'
import * as request from './request'

const API = {
  subcategories,
  categories,
  projects,
  products,
  request
}

export default API
