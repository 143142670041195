import { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import clsx from 'clsx'

import { LinkButton } from 'shared/ui'
import arrowRight from 'shared/assets/icons/arrows/arrow-right.svg'
import arrowsBottom from 'shared/assets/icons/arrows/arrows-bottom.svg'
import { ROUTES } from 'shared/consts'

import project1 from 'shared/assets/img/Projects/project-1.png'
import project2 from 'shared/assets/img/Projects/project-2.png'
import project3 from 'shared/assets/img/Projects/project-3.png'
import project4 from 'shared/assets/img/Projects/project-4.png'

import s from './Styles.module.scss'
import { Project } from './ui'

function OurProjects() {
  const [activeProject, setActiveProject] = useState(1)
  const [intervalId, setIntervalId] = useState(null)

  const projects = {
    1: {
      name: `Деклиппер. Автоматическая укладка. ГК «Черкизово»`,
      text: 'В разработке находится проект по деклиппингу и укладке батонов колбас в упаковщик. Данное решение позволит клиенту значительно повысить эффективность производства и улучшить показатели продукта.',
      img: project1
    },
    2: {
      name: 'Автоматическая линия разделки курицы. Витебская ПФ',
      text: 'Был реализован проект Линии автоматический разделки птицы, содержащий в себе модули для разделки тушки на 24 различные фрагмента. Данное решение позволило клиенту существенно оптимизировать ресурсы при процессе разделки.',
      img: project2
    },
    3: {
      name: 'Сегментатор крыла. Агрокомбинат «Колос»',
      text: 'Была реализована сегментация крыла на две и три части. Конструкция машины усовершенствована для достижения более чистого и точного реза.',
      img: project3
    },
    4: {
      name: 'Делитель колбасных батонов. Брестские традиции',
      text: 'Усовершенствованная модель делителя колбасных батонов. Оптимизированная модель ножа позволила улучшить качество реза, а более эргономичный дизайн повысил удобство эксплуатации. ',
      img: project4
    }
  }

  useEffect(() => {
    const id = setInterval(() => {
      if (activeProject === 1) {
        setActiveProject(2)
      } else if (activeProject === 2) {
        setActiveProject(3)
      } else if (activeProject === 3) {
        setActiveProject(4)
      } else if (activeProject === 4) {
        setActiveProject(1)
      }
    }, 6000)

    setIntervalId(id)

    return () => {
      clearInterval(intervalId)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeProject])

  return (
    <>
      <div className={s.preloadedImages}>
        <img src={project1} className={clsx(s.preloadedImage)} alt="Preload" />
        <img src={project2} className={clsx(s.preloadedImage)} alt="Preload" />
        <img src={project3} className={clsx(s.preloadedImage)} alt="Preload" />
        <img src={project4} className={clsx(s.preloadedImage)} alt="Preload" />
      </div>

      <div className={s.wrapper}>
        <div className={s.wrap}>
          <div className={s.tiltleWrapper}>
            <div className={s.subTiltleWrapper}>
              <p className={s.subTitle}>инжиниринг</p>
              <div className={s.subTitleLine}></div>
            </div>

            <h1 className={s.description}>
              Наши профессионалы – наша гордость. Многолетний опыт наших специалистов позволяет
              решать самые сложные задачи в вопросах автоматизации как отдельных участков, так и
              целых производственных линий. Помимо серийных машин и линий мы разрабатываем и
              конструируем машины и комплексы под индивидуальные нужды.
            </h1>
          </div>

          <div className={s.linkWrapper}>
            <LinkButton
              to={ROUTES.PORTFOLIOS}
              text="Просмотреть все проекты"
              theme="green"
              icon={arrowRight}
              alt="Arrow right"
            />
          </div>
        </div>

        <div className={s.ourProjectsWrapper}>
          <div className={s.projectsWrapper}>
            <div className={s.projects}>
              <h5 className={s.ourApproachTitle}>Примеры реализованных проектов</h5>
              <div className={s.buttonsWrapper}>
                <div
                  onClick={() => {
                    setActiveProject(1)
                    clearInterval(intervalId)
                  }}
                >
                  <Project
                    number={1}
                    name={`Деклиппер. Автоматическая укладка. ГК «Черкизово»`}
                    active={activeProject === 1}
                  />
                </div>
                <div
                  onClick={() => {
                    setActiveProject(2)
                    clearInterval(intervalId)
                  }}
                >
                  <Project
                    number={2}
                    name="Автоматическая линия разделки курицы. Витебская ПФ"
                    active={activeProject === 2}
                  />
                </div>
                <div
                  onClick={() => {
                    setActiveProject(3)
                    clearInterval(intervalId)
                  }}
                >
                  <Project
                    number={3}
                    name="Сегментатор крыла. Агрокомбинат «Колос»"
                    active={activeProject === 3}
                  />
                </div>
                <div
                  onClick={() => {
                    setActiveProject(4)
                    clearInterval(intervalId)
                  }}
                >
                  <Project
                    number={4}
                    name="Делитель колбасных батонов. Брестские традиции"
                    active={activeProject === 4}
                  />
                </div>
              </div>
            </div>

            <div className={s.projectInfo}>
              <img className={s.machineImg} src={projects[activeProject].img} alt="Project" />
              <div className={s.projectInfoTextWrapper}>
                <h3>{projects[activeProject].name}</h3>
                <p>{projects[activeProject].text}</p>
              </div>
            </div>
          </div>

          <div className={s.ourApproachWrapper}>
            <div className={s.number}>
              <p>1</p>
            </div>
            <h5 className={s.ourApproachSubTitle}>
              Проводим тесты технических решений на Вашем продукте{' '}
            </h5>
            <p className={s.ourApproachTetx}>
              Перед подписанием контракта все технические решения проходят тестирование на продукте
              заказчика.
            </p>
            <img src={arrowsBottom} alt="Arrows bottom" />
            <div className={s.number}>
              <p>2</p>
            </div>
            <h5 className={s.ourApproachSubTitle}>Минимизируем срок окупаемости проектов </h5>
            <p className={s.ourApproachTetx}>
              Основываем наши технические решения на экономике. Наша задача – срок окупаемости
              проекта для заказчика – 1-2 года.
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default observer(OurProjects)
