import { debounce } from '@mui/material'
import { makeAutoObservable } from 'mobx'
import LoadingModel from 'models/Loading'

import { API } from 'services'

class EquipmentsModel {
  categories = {}
  products = []
  isServerHasMoreProducts = false

  categorieDescription = ''

  initialLoading = LoadingModel
  loading = LoadingModel

  constructor() {
    makeAutoObservable(this)

    this.initialLoading = new LoadingModel()
    this.loading = new LoadingModel()
  }

  debounceNewPageFetch = debounce(this.fetchNewPage, 500)
  debounceSubcategoryFetch = debounce(this.fetchSubcategory, 500)

  async initialFetch(id, searchParams) {
    try {
      this.initialLoading.begin()

      const dataCategories = await API.categories.getById(id)
      const dataProducts = await API.products.get(searchParams)

      this.categories = dataCategories
      this.products = dataProducts.list

      this.isServerHasMoreProducts = dataProducts.hasNextPage
    } catch (err) {
      console.log(err)
    } finally {
      this.initialLoading.end()
    }
  }

  async fetchNewPage(searchParams) {
    try {
      this.loading.begin()

      const dataProducts = await API.products.get(searchParams)

      this.products = this.products.concat(dataProducts.list)

      this.isServerHasMoreProducts = dataProducts.hasNextPage
    } catch (err) {
      console.log(err)
    } finally {
      this.loading.end()
    }
  }

  async fetchSubcategory(searchParams) {
    try {
      this.loading.begin()

      const dataProducts = await API.products.get(searchParams)

      this.products = dataProducts.list

      this.isServerHasMoreProducts = dataProducts.hasNextPage
    } catch (err) {
      console.log(err)
    } finally {
      this.loading.end()
    }
  }

  clearProductsModel() {
    this.products = []
  }

  clearModel() {
    this.categories = []
    this.products = []
  }
}

const model = new EquipmentsModel()

export default model
