import React, { useState } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react-lite'

import { ROUTES } from 'shared/consts'

import arrowBottom from 'shared/assets/icons/arrows/arrow-bottom-black.svg'
import arrowTop from 'shared/assets/icons/arrows/arrow-top.svg'
import arrowRight from 'shared/assets/icons/arrows/arrow-right-violet-dark.svg'

import s from './Styles.module.scss'

function EquipmentMobile({ categorieWithProduct, setIsMobileMenuOpen }) {
  const navigate = useNavigate()
  const [isProductsOpen, setIsProductsOpen] = useState(false)

  return (
    <div className={s.wrapper}>
      <div className={s.categoryWrapper} onClick={() => setIsProductsOpen(!isProductsOpen)}>
        <p>{categorieWithProduct.name}</p>
        <button>
          <img src={isProductsOpen ? arrowTop : arrowBottom} alt="Arrow" />
        </button>
      </div>
      {isProductsOpen && (
        <div className={s.productListWrapper}>
          {categorieWithProduct.productList.map((product) => (
            <div
              key={product.id}
              onClick={() => {
                navigate(generatePath(ROUTES.EQUIPMENT, { id: String(product.id) }))
                setIsMobileMenuOpen(false)
              }}
              className={s.productWrapper}
            >
              <img src={arrowRight} alt="Arrow" />
              <p>{product.name}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default observer(EquipmentMobile)
