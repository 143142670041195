import { observer } from 'mobx-react-lite'
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements
} from 'react-router-dom'

import {
  AboutUs,
  Contacts,
  Engineering,
  Equipment,
  Equipments,
  Main,
  Portfolio,
  Portfolios
} from 'pages'
import { ROUTES } from 'shared/consts'

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path={ROUTES.MAIN} element={<Main />} />

      <Route path={ROUTES.ENGINEERING} element={<Engineering />} />

      <Route path={ROUTES.EQUIPMENTS} element={<Equipments />} />
      <Route path={ROUTES.EQUIPMENT} element={<Equipment />} />

      <Route path={ROUTES.ABOUT_US} element={<AboutUs />} />

      <Route path={ROUTES.CONTACTS} element={<Contacts />} />

      <Route path={ROUTES.PORTFOLIOS} element={<Portfolios />} />
      <Route path={ROUTES.PORTFOLIO} element={<Portfolio />} />
    </>
  )
)

function Router() {
  return <RouterProvider router={router} />
}

export default observer(Router)
